/*============================================================================================= 
    Company    : PT Web Architect Technology - webarq.com
    Document   : Stylesheet
    Author     : Harlan
==============================================================================================*/
@import "sass-lib";

@media all and (max-width: 767px) {

.container {width: auto;; margin: 0 20px;}
.wrapper {width: auto; margin: 0 20px;}
h1 {font-size: 20px;}
p {font-size: 14px; line-height: 24px; margin-bottom: 20px;}
.btn {font-size: 16px; line-height: 40px;}

/* header
----------------------------------------------------------------------------------------------*/
header {
    .logo {width: 100px;}
    .main_menu {
        nav {
            >ul {
                height: 100vh; padding: 20% 0 3%; overflow-y: scroll; @include boxSizing(border-box); position: relative; bottom: auto;
                li {
                    > a {font-size: 18px; padding: 12px 0;}
                    &.mn_mobile {display: block;}
                }
            }
        }
        .cart {
            >a { 
                width: 35px; height: 35px;
                span {
                    background-size: 50px !important; width: 25px; height: 25px;
                    &.tot_cart {height: 20px; line-height: 20px; width: 20px; font-size: 12px; }
                }
            }
            .drop_cart {
                /*display: block !important; opacity: 1 !important;*/ margin: 0 -15px;
                border-top: 4px solid $c_red; right: 0;
                .box_cart {
                    .arr_bx {
                        border-top: 4px solid $c_red; border-left: 4px solid $c_red; width: 15px; 
                        height: 15px;top: -13px; right: 65px;
                    }
                    .list_cart {
                        .cart_item {
                            .ket {width: 175px; margin-left: 9px;}
                        }
                    }
                    .total {
                        span {width: 68%;}
                    }
                }
            }
        }
        .burger_mnu {
            width: 30px; height: 24px; margin: 6px 0 0 20px;
            span {
                width: 30px;
                &:nth-child(2) {width:22px;}
                &:nth-child(3) {width: 30px;}
            }
        }
    }
}

.mobile_prod {
    .sub_prod {
        .nav_tab {
            width: 220px;
            a {font-size: 12px;}
        }
        .wrapper {width: auto; margin: 0 20px;}
        .content_tab {
            .tab_list {
                width: auto; max-width: 440px; margin: 0 auto; display: none;
                h6 {font-size: 12px;}
                .tabBrand {
                    .slider01, .slider02 {
                        .brand {
                            .listBrand {
                                height: 465px;
                                li {width: 25%; margin-bottom: 10px;}
                            }
                        }
                        .slick-arrow {
                            width: 23px; height: 18px; background-size: 20px !important; top: auto; bottom: -20px;
                            &.slick-next {right: 0;}
                            &.slick-prev {right: 35px; left: auto;}
                        }
                    }
                }
            }
        }
    }
    .sub_bike {
        padding: 0 0; margin: 0; left: 0; right: 0; width: 100%;
        .bike_list {height: auto;}
        .bikes_sub {
            display: none; width: auto; max-width: 440px; margin: 0 auto; 
            .nme_bike {
                float: none; display: block; margin: 0 auto 20px;
                img {margin: 10px auto;}
                h6 {margin-bottom: 5px !important;}
                .back {width: 21px; height: 18px; background-size: 20px !important;}
            }
            .listBike {
                float: none; width: auto; display: block; padding: 10px 0 60px; border-left: 0; height: auto;
                .bike {
                    display: block; width: auto; height: 260px;
                    ul {
                        li {width: 33.33%;}
                    }
                }
                .slide_bike {width: auto;}
                .slick-arrow {
                    width: 23px; height: 18px; background-size: 20px !important; top: auto; bottom: -20px;
                    &.slick-next {right: 0;}
                    &.slick-prev {right: 35px; left: auto;}
                }
            }
        }
    }
}


/* middle homepage
----------------------------------------------------------------------------------------------*/

.homepage {
    .homeSlide {
        .images {
            min-height: 480px;
            img {max-width: none; left: 50%;}
        }
        .caption {
            .container {width: auto; margin: 0 20px;}
            h1 {font-size: 34px;}
        }
        .slick-dots {display: none !important;}
    }
}
.pg_title {
    font-size: 25px; padding-left: 20px; margin-bottom: 20px;
    &:before {width: 10px; height: 10px; left: 0;}

}
.text {
    p {font-size: 14px; line-height: 24px; margin-bottom: 20px;}
}
.sec_about {padding-bottom: 35px;}
.sec_milestones {
    padding-bottom: 30px;
    .miles_content {
        .wrapper {margin: 0;}
    }
    .nav-direction {
        a {
            &:before {width: 27px; background-size: 55px; height: 31px;}
        }
    }
}
.sec_meetOur {
    background-size: auto;
    padding-bottom: 30px;
    .box_img {
        .img {
            position: relative; display: block; width: auto;
            img {width: 210px; position: relative; display: block; margin: 0 auto;}
            &:before {
                background: red; content: ''; border: 1px solid $c_e1; display: block; height: 80%; background: #fff;
                position: absolute; left: 0; right: 0; bottom: 0;
            }
        }
        .slider {
            padding-top: 0;
            .txt_meet {
                height: auto; background: none; border: none; width: auto; padding: 0; margin-top: 30px;
                h4 {font-size: 20px;}
                .name {top: 0; @include transform(translateY(0)); width: auto; display: block;}
            }
        }
        .slick-arrow {
            width: 35px;background-size: 30px !important;height: 27px;
        }
    }
}
.sec_testimoni {
    padding-top: 30px;
    .block_testi {
        .textTestimoni {
            width: auto;  display: block; float: none; border: 2px solid $c_e1; padding: 0 20px 30px;
            .slide {
                .txt_testimoni {
                    width: auto; margin-top: 90px;
                    .round_img {
                        .by { 
                            p {font-size: 14px;}
                        }
                    }
                }
            }
        }
        .Imgtestimoni {
            position: absolute; right: 0; left: auto; top: 0;
            .img {display: none;}
            .slick-arrow {
                top: 35px; left: -90%; right: auto;
                width: 35px; height: 27px; background-size: 30px !important;
                &.slick-next {left: -70%; right: auto;}
            }
        }
        .sliderTesti {
            border: none;
            &:before , .line2 {display: none;}
        }
    }
}



.page {padding: 100px 0 200px;}
.prod_pg, .news_pg {
    .wrapper {width: auto; margin: 0 20px;} 
}
.poject_pg {
    .wrapper {margin: 0; width: auto;}
}
.project.list {background-size: 940px;}
.breadcrumb {
    margin: -15px 0 60px 20px;
    a {font-size: 12px;}
    .pos {font-size: 15px;}
}
.top_fil {
    padding: 0; background: none; border: none; display: flex; margin: 20px 0;
    @include flex(column);
    .left {float: none; display: block; @include order(2);}
    .right {
        float: none; display: block;@include order(1); padding: 10px; background: #fff;
        position: relative;
        form {
            display: block;
            input[type="text"] {width: 100%; display: inline-block;}
            input[type="submit"] {position: absolute; top: 0; bottom: 0; margin: auto; height: 38px;}
        }
    }
    .sort {
        margin-top: 15px;
        label {font-size: 13px;}
        select {font-size: 13px; background-size: 12px; padding: 10px; width: 130px;}
        .cover_combo {
            .inner_combo {font-size: 13px;}
        }
    }
}
.asside {
    width: auto; float: none; display: block; margin: 0 0 30px;
    .filter {
        padding: 0 15px 15px;
        h6 {padding: 15px 0 0;}
        .box_price {
            .col {
                float: none; width: auto; display: block;
                &:nth-child(2) {float: none; display: block;}
            }
        }
        #price_range {margin: 25px 0 10px;}
        .drop_filt {
            a {padding: 15px 0;}
        }
    }
}
.content_right {
    float: none; width: auto; display: block;
}
.col_list {
    .col {
        .img {
            img {display: block; margin: 0 auto;}
        }
        .ket_prod {
            padding: 0 10px 15px;
            h5 {font-size: 14px;}
            h6 {font-size: 14px;}
        }
    }
}
.decription  {
    padding: 30px 0; border-bottom: 1px solid $c_e1;
    p {font-size: 14px; line-height: 24px;}
}
.related {
    .col_list {
        .col {
            &:nth-child(2) {border-right: 1px solid $c_e1;}
        }
    }
}
.detail_prod {
    border-bottom: 1px solid $c_e1;
    .img_big {
        .list_img {width: 236px; height: 236px;}
        .thumb {
            a {width: 48px;}
        }
    }
    .dec_prod {
        h5 {font-size: 16px; margin-bottom: 20px;}
        ul {
            li {
                font-size: 14px; margin-bottom: 10px; padding-left: 20px;
                &:before {left:0; top: 5px;}
            }
        }
        .pqt {
            padding-right: 0;
            h5 {font-size: 18px;}
            .tot {
                h5 {font-size: 22px;}
            }
            .ket {
                label {font-size: 14px;}
            }
        }
        .btn {line-height: 40px;}
    }
}
.qty_input {
    width: 95px;
    input {font-size: 16px; width: 30px;}
    button {
        &.min {font-size: 26px;}
        &.plus {font-size: 22px;}
    }
}
.cart_pg {
    padding: 20px 10px;
    h4 {font-size: 16px;}
    .shopCart_list {
        .item {
            .blok {
                &:nth-of-type(1) {width: 50% !important; max-width: 230px;}
                &:nth-of-type(2) {width: 50% !important;}
                &:nth-of-type(3), &:nth-of-type(4) {display: block; width: 50% !important; border-top: 1px solid $c_e1; float: left; padding: 15px 10px;}
                &:nth-of-type(5) {display: block; width: 100% !important; border-top: 1px solid $c_e1; float: left; text-align: center;}
                h5 {line-height: 24px;}
                label {margin-bottom: 6px;}
                .qty_input {
                    width: 75px; padding: 0 20px;
                    input {width: 30px; font-size: 11px; line-height: 24px;}
                    button {
                        width: 20px; line-height: 18px;-webkit-appearance: none; appearance: none; -moz-appearance: none;
                        &.min {line-height: 18px; left: 0; right: auto; padding: 0;}
                        &.plus {font-size: 16px; right: 0; left: auto; padding-top: 1.5px;}
                    }
                }
            }

        }
    }
    .ckout {
        .row {
            p {font-size: 14px; line-height: 20px;}
            label {font-size: 14px;}
            .col {
                float: none; width: auto; display: block; margin-bottom: 15px;
                &:nth-child(2) {display: block; width: auto; float: none;}
                .btn {
                    &.right {width: 260px; float: none;}
                }
            }
            input[type="text"], input[type="email"], select, textarea {font-size: 14px; padding: 10px;}
            textarea {height: 150px;}
        }
        input[type="submit"].btn {line-height: 40px; margin-top: 0;}
        .btm_cart {
            margin-top: 20px;
            .left {}
        }
        &.apply {
            .input_file {
                height: 36px;
                span {font-size: 14px; padding: 0 10px;}
                input[type="file"] {padding: 7px 0;}
                label {font-size: 12px;}
            }
            .g-recaptcha {@include transform(scale(0.9)); margin-left: -35px;}
            input[type="submit"].btn {width: 100%; max-width: 300px;}
        }
    }
}
.btm_cart {
    .right {
        position: relative; z-index: 1;
        .btn {width: 260px;}
        h6 {
            font-size: 14px;
            span {display: block; margin-top: 10px; font-size: 22px;}
        }
    }
    .back_shop {
        font-size: 14px; position: relative; z-index: 2;
        &:before {margin-right: 6px;}
    }
}
.text_thanks {
    h1 {font-size: 40px; margin-bottom: 30px;}
    p {font-size: 14px; line-height: 24px;}
    .btn {width: 260px; display: block; margin: 0 auto;}
    &.karir {
        h1 {font-size: 40px; margin-bottom: 30px;}
        p {font-size: 14px;}
    }
}
.left_project {
    .project_content {
        .slide_project1 {
            .item {
                .dec_project {
                    h1 {font-size: 20px;}
                    .rd_more {font-size: 14px;}
                }
            }
        }
    }
    .thumb_project {
        .list_thumb {
            width: 280px;
            .thumb {width: 84px !important; height: 84px;}
            .slick-arrow {
                width: 31px; height: 27px; background-size: 30px !important;
                &.slick-next {right: -40px;}
                &.slick-prev {left: -40px;}
            }
        }
    }
    .more_project {font-size: 14px; width: 120px;}
}
.listCareer {
    .list {
        div {
            &.submit {
                .btn {line-height: 40px;}
            }
        }
    }
}
.detailCareer {
    .ket_career {
        p {font-size: 14px; line-height: 24px;}
        ul {
            li {
                font-size: 14px; padding-left: 20px;
                &:before {top: 3px; width: 6px; height: 6px; left: 0;}
            }
        }
        .btn {line-height: 40px; width: 100%; max-width: 300px;}
    }
}
.career {
    .cart_pg {padding: 0;}
}


.contact_sec {
    h2 {
        padding-left: 20px; font-size: 20px;
        &:before {top: 4px;}
    }
    .list_location {
        margin-bottom: 40px; padding-bottom: 40px; border-bottom: 1px solid $c_e1;
        .location {
            float: none; width: auto; display: block; margin: 0 0 20px; padding-bottom: 20px; border-bottom: 1px solid $c_e1;
            h2 {
                font-size: 16px;
                &:before {height: 6px; width: 6px; top: 3px;}
            }
            p {font-size: 14px;}
            &:last-child {margin-bottom: 0; padding-bottom: 0; border: none;}
        }
    }
    .contact_form {
        .img.right {display: none;}
        .formContact {
            float: none; width: auto; display: block;
            p {font-size: 14px;}
            .row {
                label {font-size: 14px;}
                input[type="text"], input[type="email"], select, textarea {font-size: 14px; padding: 10px;}
                input[type="submit"],button,input[type="button"] {line-height: 40px;}
            }
        }
    }
}
.page.news {background-size: 215%;}
.news {
    .breadcrumb {
        margin-bottom: 30px; 
    }
}
.news_pg {
    .date {font-size: 14px; margin-bottom: 15px;}
    .rd_more {font-size: 14px;}
    .load_more {font-size: 14px;}
    .big_news {
        .hdline {
            .img {
                float: none;  display: block; width: auto; height: auto;
                img {margin: 0 auto; max-width: 600px; display: block; width: 100%;}
            }
            .dec_news {
                float: none; display: block; margin: 0; width: auto; margin: 0 -20px;
                padding: 20px;
                h1 {line-height: 26px;}
            }
        }
    }
    .grid_news {
        width: auto; margin-top: 40px;
        .grid_item {
            width: auto; height: auto;
            &:nth-of-type(2),
            &:nth-of-type(4) {margin-top: 0;}
            .img, .dec {width: auto; height: auto; position: relative;}
            .dec {
                padding: 20px 20px 65px;
                .date {margin-bottom: 25px;}
                a {left: 20px; bottom: 15px;}
                h5 {font-size: 18px; line-height: 24px; margin-bottom: 20px;}
            }
        }
    }
}
.detailNews {
    margin-bottom: 30px; padding-bottom: 20px;
}
.dot {font-size: 18px;}
.recomend {
    .dot {margin-bottom: 30px;}
}
.slide_recomend {
    .slide {width: 320px;}
    .slick-arrow {
        width: 31px; height: 27px; background-size: 30px !important;
    }
}
.SlideText {
    width: 320px;
    h4 {font-size: 18px; line-height: 26px;}
    p {display: none;}
}

.detail_listPro {
    .slideSmall {
        margin: 20px 65px 0;
        .thmb {width: 100px;}
        .slick-arrow {
            width: 31px; height: 27px; background-size: 30px !important;
            &.slick-prev {left: -40px;}
            &.slick-next {right: -40px;}
        }
    }
}

.scrolltoTop {
    width: 23px; height: 30px; background-size: 100% !important; bottom: 120px; right: 20px;
}
#fbplus-right-ico {right: 15px !important;}
#fbplus-left-ico {left: 15px !important;}
/* footer
----------------------------------------------------------------------------------------------*/
footer {
    padding-top: 0;
    .foot {
        margin: 12px 0;
        &.left {
            display: block;float: none;
            ul {
                li {
                    margin: 0 3px;
                    a {width: 32px; height: 32px; background-size: 22px !important;}
                }
            }
        }
        &.center {
            display: none;
        }
        &.right {
            display: block; float: none; background: #fff; margin: 0; padding: 10px 0; margin: 0 -20px;
            a {color: #000; border-right: 1px solid #000; font-size: 12px; line-height: 14px;}
            ul {
                li {
                    a {color: #000; border-color: #000;}
                }
            }
        }
    }
}



.fancybox-button {width: 35px !important; height: 35px !important;}
.fancybox-button--close {background-size: 25px !important;}
.fancybox-arrow--left:after,
.fancybox-arrow--right:after {width: 30px !important; height: 30px !important; background-size: 25px !important;}

.fancybox-slide .fancybox-image-wrap {left: 20px !important; right: 20px !important; width: auto !important;}
/*.fancybox-slide .fancybox-image-wrap img {width: auto !important;}*/



}

@media all and (max-width: 639px) {

header {
    .main_menu {
        nav {
            > ul {padding-top: 18%;}
        }
    }
}
.mobile_prod {
    .sub_prod {
        .close_tab {width: 16px; height: 16px;  background-size: 16px;}
        .nav_tab {
            width: 200px;
            a {font-size: 12px;}
        }
    }
}
.top_fil {
    .sort {
        display: block; margin-right: 0;
        label {width: 70px; display: inline-block;}
        select {width: 200px;}
    }
}
.col_list {
    .col {
        width: 50%;
        &:nth-child(3n){border-right: 1px solid $c_e1;}
        &:nth-child(2n) {border-right: none;}
        .more_det {font-size: 14px; padding: 20px 0;}
    }
    &.listProject {
        .col {
            float: none; width: auto; padding: 10px 0; border-right: none;
            .img {width: 100%}
        }
    }
    &.col-5 {
        .col {
            width: 50%;
            &:nth-child(4n),
            &:nth-child(5n) {border-right: 1px solid $c_e1;}
            &:nth-child(2n) {border-right: none;}
        }
    }
}
.related {
    .col_list {
        .col {
            width: 49.25%; margin-right: 1.5%;
            &:nth-child(2) {margin-right:0; }
            &:nth-child(3) {display: none;}
        }
    }
}
.detail_prod {
    padding-top: 25px;
    .img_big {
        float: none; display: block; width: auto; padding-left: 0;margin: 0 10px 25px;
        img {display: block; margin: 0 auto;}
        .list_img {width: 350px; height: 350px; margin: 0 auto;}
        .thumb {
            text-align: center;
            a {width: 50px;}
        }
    }
    .dec_prod {
        display: block; float: none; width: auto; margin: 0 10px;
        ul {
            width: auto;
        }
        .pqt {
            margin: 20px 0;
            .ket {
                width: 49%; margin-right: 2%;
                label {margin-bottom: 10px;}
                &:nth-child(2) {
                    width: 49%; text-align: right; margin-right: 0;
                    .qty_input {float: right;}
                }
                &:nth-child(3) {
                    width: 100%; margin-right: 0; padding-top: 20px; margin-top: 20px; border-top: 1px solid $c_e1;
                }
            }
        }
        .btn {margin: 0 auto; width: 260px;}
    }
}
.listCareer {
    .list {
        display: block;
        div {
            display: block; width: auto !important; padding: 0 !important; margin-bottom: 20px;
            &.submit {
                margin-bottom: 0; text-align: left;
                .btn {width: 100%; max-width: 300px; display: block; margin: 0 auto;}
            }
        }
    }
}
.top_career {
    .loc , h5{float: none;}
    .loc {font-size: 14px;}
}
.detail_listPro {
    .slideSmall {
        margin: 20px 48px 0;
    }
}

.cart_pg {
    .ckout {
        .row {
            .right {
                float: left;
                .g-recaptcha {@include transform(scale(0.9));}
            }
        }
        &.apply {
            .g-recaptcha {margin-left: -22px;}
        }
    }
}



}


@media all and (max-width: 479px) {

    .mobile_prod {
        .sub_prod {
            padding-bottom: 20px;
            .content_tab {
                .tab_list {
                    .tabBrand {
                        .slider01, .slider02 {
                            .brand {
                                .listBrand {
                                    min-height: 315px; height: auto;
                                }
                            }
                        }
                    }
                }
            }
        }
        .sub_bike {
            .bikes_sub {
                .nme_bike {
                    margin-bottom: 10px;
                    img {max-width: 120px;}
                    .back {margin-top: 10px;}
                }
                .listBike {
                    padding: 0 0 30px;
                    .bike {
                        display: block; width: auto; height: auto;
                        ul {
                            li {
                                width: 50%; margin-bottom: 10px; padding: 0 20px;
                                &:nth-child(5),
                                &:nth-child(6), &:nth-child(7),
                                &:nth-child(8) {display: none !important;}
                            }
                        }
                    }
                }
            }
        }
    }

    .btm_cart {
        .right {
            margin-top: -12px;
        }
    }
    .cart_pg {
        .ckout {
            .btm_cart {
                margin-top: 20px;
                .left {display: none;}
                .right {float: none; display: block;  margin: 20px 0 0;}
            }
            &.apply {
                .g-recaptcha {margin-left: -15px;}
            }
        }
    }
    .sec_milestones {
        .miles_content {
            .slide_miles {
                .miles {
                    .images {
                        height: 280px;
                        img {margin: -10% auto;}
                    }
                }
            }
        }
        .nav-direction {top: 300px;}
    }
    .sec_testimoni {
        .block_testi {
            .Imgtestimoni {
                .slick-arrow {
                    &.slick-next {left: -64%;}
                }
            }
        }
    }
    .slide_recomend {
        width: auto; margin: 0 20px;
        .slick-arrow {display: none !important;}
    }
    .text_recomend {width: auto; margin: 0 20px;}
    .SlideText {
        width: 280px;
        h4 {padding-right: 20px;}
    }
    .left_project {
        .thumb_project {
            .list_thumb {
                width: 200px;
                .thumb {width: 57px !important; height: 57px;}
            }
        }
    }
    .detail_listPro {
        .slideSmall {
            margin: 20px 26px 0;
        }
    }
    .g-recaptcha {@include transform(scale(0.85)); margin-left: -20px;}

    .detail_prod {
        .img_big {
            .list_img {width: 242px; height: 242px;}
        }
    }

}

@media all and (max-width: 410px) {
    header {
        .main_menu {
            .cart {
                .drop_cart {
                    width: auto; min-width: 310px;
                    .box_cart {width: auto;}
                }
            }
        }
    }
    .sub_prod .content_tab .tab_list {padding-bottom: 70px;}
}



@media all and (max-height: 500px) {
    .mobile_prod {
        .sub_prod {
            .content_tab {
                .tab_list {
                    .tabBrand {height: 60vh; overflow-y: auto; overflow-x: hidden;}
                }
            }
        }
        .sub_bike {
            .bikes_sub {height: 75vh; overflow-y: auto;}
        }
    }
    .mobile_prod {
        .sub_prod {
            .content_tab {
                .tab_list {
                    .tabBrand {
                        .slider01,.slider02 {
                            .brand {
                                .listBrand {height: auto;}
                            }
                        }
                    }
                }
            }
        }
    }
}
@media all and (max-height: 479px) {
    .homepage {
        min-height: 480px; height: auto;
        .homeSlide {
            .images {height: auto !important;}
            .slide {min-height: 480px;}
            .caption {bottom: 30%;}
        }
    }
    header {
        .main_menu {
            nav {
                > ul {padding: 5% 0 6%; margin: 30px 0; min-height: 90vh;}
            }
        }
    }
}

@media all and (max-height: 340px) {
    .mobile_prod {
        .sub_prod {
            .content_tab {
                .tab_list {
                    .tabBrand {height: 30vh; overflow-y: auto; overflow-x: hidden;}
                }
            }
        }
        .sub_bike {
            .bikes_sub {height: 55vh; overflow-y: auto;}
        }
    }
}
@media all and (max-width: 330px) {
    header {
        .main_menu {
            nav {
                > ul {padding: 22% 0 3%;}
            }
        }
    }
    .mobile_prod {
        .sub_bike {
            .bikes_sub {
                .listBike {
                    .bike {
                        ul {
                            li {
                                padding: 0 10px;
                            }
                        }
                    }
                }
            }
        }
    }

}

